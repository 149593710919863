<template>
  <el-main>
    <el-button style="margin-bottom: 10px" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ path: '/store/addStore' })">添加商户</el-button>
    <!-- 搜索 -->
    <el-form :inline="true" :model="search" class="el-form-search" label-width="100px">
      <el-form-item label="商户名称：">
        <el-input size="small" width="200" class="search_input" v-model="search.Storename" placeholder="请输入商户名称/电话"></el-input>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker
          size="small"
          class="search_input"
          v-model="search.Registerdate"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="商户状态：">
        <el-select size="small" v-model="search.Storestatus" filterable>
          <el-option v-for="item in StorestatusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商户类型：">
        <el-select size="small" v-model="search.active_type" filterable>
          <el-option v-for="item in store_type_list" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getList">搜索</el-button>
        <el-button size="small" @click="exportEx">导出</el-button>
        <el-button size="small" type="text" @click="resetSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table fit :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="border-left: 1px solid #e8eef1; border-right: 1px solid #e8eef1">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="logo" align="right" width="80">
        <template slot-scope="scope">
          <img class="tableimg" :src="scope.row.store_logo" />
        </template>
      </el-table-column>
      <el-table-column prop="store_name" label="商户名称" width="150"></el-table-column>
      <el-table-column prop="store_phone" label="商户电话" align="center" width="140"></el-table-column>
      <el-table-column label="商户类型" align="center" width="140">
        <template v-slot="{ row }">
          <el-tag v-if="row.store_type == 1" type="danger">数字门店</el-tag>
          <el-tag v-if="row.store_type == 2" type="success">线下门店</el-tag>
          <el-tag v-if="row.store_type == 3" type="warning">供应链</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="store_site" align="center" label="地址"></el-table-column>
      <el-table-column prop="store_staff_sum" label="店员数" align="center" width="70"></el-table-column>
      <el-table-column prop="store_goods_sum" label="商品数" align="center" width="110"></el-table-column>
      <el-table-column prop="store_order_sum" label="订单数" align="center" width="110"></el-table-column>
      <el-table-column prop="store_turnover" label="营业额" align="center" width="130"></el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center" width="160">
        <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="is_open" label="店铺状态" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <el-tag size="small" :type="scope.row.is_open == 1 ? 'primary' : 'info'" style="width: 68px" effect="dark">{{ scope.row.is_open === 1 ? '营业中' : '歇业' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="240" fixed="right">
        <template #header>
          <div class="edit">
            <span>操作</span>
            <!-- <i class="el-icon-edit-outline"></i> -->
          </div>
        </template>
        <template slot-scope="scope">
          <div class="storeCode">
            <el-button size="small" type="text" @click="storeManagement(scope.row)">商户管理</el-button>
            <el-button size="small" type="text" @click="editStore(scope.row)">编辑</el-button>
            <el-button size="small" type="text" @click="deleteStore(scope.row.id)">删除</el-button>
            <el-popover style="margin-left: 10px" width="180" height="150px" placement="left" trigger="click">
              <el-image @click="showwxcode" :src="scope.row.ercode"></el-image>
              <el-button slot="reference" @click="QRcode(scope.row, scope.row.id)" type="text" size="small">二维码</el-button>
            </el-popover>
            <el-link :href="storeurl" size="small" target="_blank" type="primary" :underline="false">跳转商户</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="tablefooter">
      <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    </div>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { inspectPhone } from '@/util/verification';
import { getDateformat } from '@/util/getDate';
import config from '@/util/config';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data: function () {
    // 数字门店 -可以购买的线上店铺
    // 线下门店 -没有线上商品 线上只做展示
    // 供应链 -负责供货
    return {
      storeurl: config.storeurl,
      total: 0,
      page: 1,
      pageNum: 10,
      search: {
        Storename: null,
        Registerdate: null,
        Storestatus: 0,
        active_type: 0,
      },
      StorestatusArr: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '歇业',
        },
        {
          value: 2,
          label: '营业中',
        },
      ],
      list: [],
      store_type_list: [
        { type: 0, name: '全部' },
        { type: 1, name: '数字门店' },
        { type: 2, name: '线下门店' },
        { type: 3, name: '供应链' },
      ],
      getDateformat: null,
    };
  },
  methods: {
    // 导出数据成excel
    exportEx() {
      let search = this.search;
      let that = this;
      this.$axios
        .post(this.$api.store.StoreExport, {
          store_name: inspectPhone(search.Storename) ? null : search.Storename,
          store_phone: inspectPhone(search.Storename) ? search.Storename : null,
          is_open: search.Storestatus === 0 ? null : search.Storestatus,
          start_time: search.Registerdate ? search.Registerdate[0] / 1000 : null,
          end_time: search.Registerdate ? search.Registerdate[1] / 1000 : null,
        })
        .then(res => {
          if (res.code == 0) {
            let path = config.baseurl + '/' + res.result;
            let a = document.createElement('a');
            a.href = path;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 分页操作
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      this.getList();
    },
    // 清空搜索
    resetSearch() {
      this.search = {
        Storename: null,
        Registerdate: null,
        Storestatus: 0,
        active_type: 0,
      };
      this.getList();
    },
    // 获取数据
    getList() {
      let search = this.search;
      this.$axios
        .post(this.$api.store.storeList, {
          store_name: inspectPhone(search.Storename) ? null : search.Storename,
          store_phone: inspectPhone(search.Storename) ? search.Storename : null,
          is_open: search.Storestatus === 0 ? null : search.Storestatus,
          start_time: search.Registerdate ? search.Registerdate[0] / 1000 : null,
          end_time: search.Registerdate ? search.Registerdate[1] / 1000 : null,
          page: this.page,
          rows: this.pageNum,
          store_type: search.active_type ? search.active_type : null,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 删除商户
    deleteStore(id) {
      this.$confirm('确认要将此商户删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post(this.$api.store.delStore, {
              id: id,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                });
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(err => {});
    },
    // 显示二维码
    QRcode(row, id) {
      if (row.ercode) {
        setTimeout(() => {
          this.$set(row, 'isErcode', true);
        }, 100);
        return;
      }
      let that = this;
      this.$axios
        .post(this.$api.store.storeErCode, {
          id,
        })
        .then(res => {
          if (res.code == 0) {
            that.$set(row, 'ercode', config.baseurl + '/' + res.result);
            that.$set(row, 'isErcode', true);
          }
        });
    },
    // 跳转商户管理
    storeManagement(item) {
      let info = {
        id: item.id,
        store_name: item.store_name,
      };
      this.$router.push({
        path: `/store/storeManagement?info=${encodeURIComponent(JSON.stringify(info))}`,
      });
    },
    // 修改商户
    editStore(item) {
      let info = {
        id: item.id,
        store_name: item.store_name,
      };
      this.$router.push({
        path: `/store/editStore?info=${encodeURIComponent(JSON.stringify(info))}`,
      });
    },
  },
  created: function () {
    this.getList();
    this.getDateformat = getDateformat;
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.tableimg {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.storeCode {
  .codeBox {
    position: absolute;
    left: 46px;
    bottom: -125px;
    width: 200px;
    height: 200px;
    z-index: 5;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
